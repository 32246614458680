import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Autocomplete, Col, Input, Row, Select } from '@/components/atoms'
import { isAccountVisible } from '@/features/gate/utils'
import { ISRVisitSchema } from '@/features/isr/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import { classListSelectOptions, fuelTypeSelectOptions } from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
  appointmentType: AppointmentTypes
}

const VisitDetailsPowerUnit: FC<IProps> = (props) => {
  const { accounts, appointmentType } = props
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof ISRVisitSchema>>()

  const showAccount = isAccountVisible(appointmentType)

  return (
    <Col items="stretch" gap={20}>
      <Row gap={16}>
        <Input
          {...register('power_unit_owner_id')}
          uppercase
          fullWidth
          label="ID"
          error={!!errors.power_unit_owner_id}
          helperText={errors.power_unit_owner_id?.message}
        />
        <Input
          {...register('power_unit_carrier_usdot')}
          fullWidth
          digitsOnly
          type="number"
          label="US DOT #"
          error={!!errors.power_unit_carrier_usdot}
          helperText={errors.power_unit_carrier_usdot?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_license_plate_number')}
          uppercase
          fullWidth
          required
          label="LPN"
          error={!!errors.power_unit_license_plate_number}
          helperText={errors.power_unit_license_plate_number?.message}
        />

        <Controller
          name="power_unit_license_plate_state"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              required
              freeSolo={false}
              label="LPN State/Region"
              options={statesOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={(value: ISelectOption) =>
                field.onChange(value?.id || '')
              }
              isOptionEqualToValue={(option: ISelectOption) =>
                option.id === field.value
              }
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_vin')}
          uppercase
          fullWidth
          label="VIN"
          error={!!errors.power_unit_vin}
          helperText={errors.power_unit_vin?.message}
        />

        <Controller
          name="power_unit_weight_class"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Class"
              value={field.value}
              options={classListSelectOptions}
              error={!!formState.errors.power_unit_weight_class}
              helperText={formState.errors.power_unit_weight_class?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Controller
          name="power_unit_fuel_type"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Fuel Type"
              value={field.value}
              options={fuelTypeSelectOptions}
              error={!!formState.errors.power_unit_fuel_type}
              helperText={formState.errors.power_unit_fuel_type?.message}
              onChange={field.onChange}
            />
          )}
        />

        {showAccount && (
          <Controller
            name="account_name"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                freeSolo={false}
                label="Account"
                options={accounts}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(value: ISelectOption) =>
                  field.onChange(value?.id || '')
                }
                isOptionEqualToValue={(option: ISelectOption) =>
                  option.id === field.value
                }
              />
            )}
          />
        )}
      </Row>
    </Col>
  )
}

export default VisitDetailsPowerUnit
