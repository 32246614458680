import * as yup from 'yup'

import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

const validations = {
  isVisible: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType)
  },

  isNotMinimized: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType) &&
      !isCargoAssetMinimized(puType)
  },

  isNotEmpty: {
    keys: ['appointment_type', 'power_unit_type', 'load_status'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      isCargoAssetVisible(appointmentType, puType) && !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: ['appointment_type', 'power_unit_type', 'cargo_asset_asset_type'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      assetType: CargoAssetTypes
    ) =>
      isCargoAssetVisible(appointmentType, puType) &&
      isChassisIdVisible(puType, assetType)
  }
}

const CargoAssetDetailsSchema = yup.object({
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .required('Type is required'),
  account_name: yup.string(),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(CargoAssetTypes))
          .required('Asset type is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('ID is required'),
    otherwise: (schema) => schema
  }),
  cargo_asset_carrier_name: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  chassis_id: yup.string().when(validations.isChassisVisible.keys, {
    is: validations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup.mixed<LoadTypes>().when(validations.isVisible.keys, {
    is: validations.isVisible.validate,
    then: (schema) =>
      schema
        .oneOf(Object.values(LoadTypes))
        .required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipment_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired(),

  inspection_completed: yup.boolean().notRequired()
})

export default CargoAssetDetailsSchema
