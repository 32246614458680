import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import {
  Autocomplete,
  Checkbox,
  Col,
  Input,
  Row,
  Select
} from '@/components/atoms'
import { SEAL_NUMBER_HELPER } from '@/constants'
import { SuggestedItems } from '@/features/gate'
import { useLazyGetCarriersQuery } from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import {
  CheckInSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import {
  cargoAssetTypeSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

interface IProps {
  suggestedIds: string[]
  orgId: string
}

const CheckInCargoAssetFormSection: FC<IProps> = (props) => {
  const { suggestedIds, orgId } = props

  const [selectedSuggestedId, setSelectedSuggestedId] = useState<
    string | undefined
  >()

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const [carriersList, setCarriersList] = useState<Carrier[]>([])

  const puType = watch('power_unit_type')
  const assetType = watch('cargo_asset_asset_type')
  const loadStatus = watch('load_status')
  const cargoAssetId = watch('cargo_asset_owner_id')

  const sealMatches = watch('seal_matchPW')
  const inspectionCompleted = watch('inspection_completed')

  const isMinimized = isCargoAssetMinimized(puType)
  const isChassisVisible = isChassisIdVisible(puType, assetType)
  const isEmpty = isAssetEmpty(loadStatus)

  const carrierDebounced = useDebounce(watch('cargo_asset_carrier_name'), 800)

  const [getCarriers, { isFetching: carriersLoading }] =
    useLazyGetCarriersQuery()

  const onSelectSuggestedItem = (value: string) => {
    setSelectedSuggestedId(value)
    setValue('cargo_asset_owner_id', value, { shouldValidate: true })
  }

  const fetchCarriersList = async () => {
    const carriers = await getCarriers({
      org_id: orgId,
      partial_name: carrierDebounced || ''
    })

    setCarriersList(carriers?.data?.data?.carriers || [])
  }

  useEffect(() => {
    if (cargoAssetId !== selectedSuggestedId) {
      setSelectedSuggestedId(undefined)
    }
  }, [cargoAssetId])

  useEffect(() => {
    fetchCarriersList()
  }, [carrierDebounced])

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          <Controller
            name="cargo_asset_asset_type"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Asset Type"
                name={field.name}
                value={field.value}
                error={!!formState.errors.cargo_asset_asset_type}
                helperText={formState.errors.cargo_asset_asset_type?.message}
                options={cargoAssetTypeSelectOptions}
                onChange={field.onChange}
              />
            )}
          />

          <Col gap={7}>
            <Input
              {...register('cargo_asset_owner_id')}
              required
              uppercase
              fullWidth
              label="Asset ID"
              error={!!errors.cargo_asset_owner_id}
              helperText={errors.cargo_asset_owner_id?.message}
            />

            {suggestedIds.length > 1 && (
              <SuggestedItems
                suggestedItems={suggestedIds || []}
                selectedId={selectedSuggestedId}
                onChange={onSelectSuggestedItem}
              />
            )}
          </Col>

          <Controller
            name="cargo_asset_carrier_name"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                required
                label="Carrier"
                name={field.name}
                inputValue={field.value}
                options={carriersList || []}
                loading={carriersLoading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onInputChange={(e, newValue) => field.onChange(newValue)}
                getOptionLabel={(option: Carrier) => option?.name || ''}
                customOptionLabel={(option: Carrier) =>
                  `${option.name} (${option.us_dot_number})`
                }
                isOptionEqualToValue={(option: Carrier) =>
                  option.name === field.value
                }
                onChange={(value: Carrier) => {
                  field.onChange(value?.name || '')
                }}
                inputProps={{
                  uppercase: true
                }}
              />
            )}
          />
        </>
      )}

      <Col>
        <Row gap={16}>
          <Controller
            name="load_status"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                name={field.name}
                label="Load Status"
                value={field.value}
                options={loadStatusSelectOptions}
                error={!!formState.errors.load_status}
                helperText={formState.errors.load_status?.message}
                onChange={field.onChange}
              />
            )}
          />

          {!isEmpty && (
            <Input
              {...register('shipment_number')}
              uppercase
              fullWidth
              label="Inbound Ref #"
              error={!!errors.shipment_number}
              helperText={errors.shipment_number?.message}
            />
          )}
        </Row>

        <Checkbox
          value={inspectionCompleted}
          label="Inspection complete"
          onChange={() =>
            setValue('inspection_completed', !inspectionCompleted)
          }
        />
      </Col>

      {isChassisVisible && (
        <Input
          {...register('chassis_id')}
          uppercase
          fullWidth
          label="Chassis ID"
          error={!!errors.chassis_id}
          helperText={errors.chassis_id?.message}
        />
      )}

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Controller
            name="seal_numbers"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                fullWidth
                type="multiple"
                label="Seal #"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || SEAL_NUMBER_HELPER}
              />
            )}
          />

          <Checkbox
            value={sealMatches}
            label="The seal matches the paperwork "
            onChange={() => setValue('seal_matchPW', !sealMatches)}
          />
        </Col>
      )}

      {!isMinimized && (
        <Row gap={16}>
          <Input
            {...register('cargo_asset_license_plate_number')}
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.cargo_asset_license_plate_number}
            helperText={errors.cargo_asset_license_plate_number?.message}
          />

          <Controller
            name="cargo_asset_license_plate_state"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                freeSolo={false}
                name={field.name}
                label="LPN State/Region"
                options={statesOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(value: ISelectOption) =>
                  field.onChange(value?.id || '')
                }
                isOptionEqualToValue={(option: ISelectOption) =>
                  option.id === field.value
                }
              />
            )}
          />
        </Row>
      )}
    </Col>
  )
}

export default CheckInCargoAssetFormSection
