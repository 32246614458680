import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import {
  Autocomplete,
  Checkbox,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/components/atoms'
import { SEAL_NUMBER_HELPER } from '@/constants'
import { useGetCargoAssetsOnsiteQuery } from '@/features/gate/api'
import {
  CheckOutSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'
import { CargoAsset } from '@/features/yard/types'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import {
  cargoAssetTypesFriendlyNames,
  loadStatusSelectOptions
} from '@/utils/mappers'
import { userFriendlyState } from '@/utils/table'

interface IProps {
  siteId: string
  orgId: string
}

const CheckOutCargoAssetFormSection: FC<IProps> = (props) => {
  const { siteId, orgId } = props

  const {
    register,
    watch,
    setValue,
    control,
    trigger,
    formState: { errors, dirtyFields }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const idDebounce = useDebounce(watch('cargo_asset_owner_id'), 800)
  const lpnDebounce = useDebounce(
    watch('cargo_asset_license_plate_number'),
    800
  )

  const { data: assetsById, isFetching: idsLoading } =
    useGetCargoAssetsOnsiteQuery({
      site_id: siteId,
      org_id: orgId,
      type: 'id',
      cargo_asset_owner_id_prefix: idDebounce
    })

  const { data: assetsByLpn, isFetching: lpnLoading } =
    useGetCargoAssetsOnsiteQuery({
      site_id: siteId,
      org_id: orgId,
      type: 'lpn',
      cargo_asset_lpn_prefix: lpnDebounce
    })

  const puType = watch('power_unit_type')
  const assetType = watch('cargo_asset_asset_type')
  const loadStatus = watch('load_status')
  const sealMatchesPW = !!watch('seal_matchPW')
  const assetCarrier = watch('cargo_asset_carrier_name')
  const chassisName = watch('chassis_id')
  const assetLpnState = watch('cargo_asset_license_plate_state')

  const isEmpty = isAssetEmpty(loadStatus as LoadTypes)
  const isMinimized = isCargoAssetMinimized(puType as PowerUnitTypes)
  const isChassisVisible = isChassisIdVisible(
    puType as PowerUnitTypes,
    assetType as CargoAssetTypes
  )

  const populateCargoAssetFields = (event: CargoAsset | undefined) => {
    setSelectedEventId(event?.id)

    setValue(
      'cargo_asset_asset_type',
      event ? event.metadata.cargo_asset_asset_type : undefined
    )
    setValue(
      'cargo_asset_owner_id',
      event ? event.metadata.cargo_asset_owner_id : '',
      { shouldValidate: true }
    )
    setValue(
      'cargo_asset_carrier_name',
      event ? event.metadata.cargo_asset_carrier_name : ''
    )
    setValue('load_status', event ? event.metadata.load_status : undefined)
    setValue('chassis_id', event ? event.metadata.chassis_id : '')
    setValue(
      'cargo_asset_license_plate_number',
      event ? event.metadata.cargo_asset_license_plate_number : ''
    )
    setValue(
      'cargo_asset_license_plate_state',
      event ? event.metadata.cargo_asset_license_plate_state : ''
    )

    trigger('cargo_asset_owner_id')
    trigger('cargo_asset_license_plate_number')
    trigger('load_status')
  }

  const fulfillInitialFormValues = (type: 'id' | 'lpn') => {
    if (type === 'id') {
      const isIdTouched = dirtyFields.cargo_asset_owner_id
      const assetId = watch('cargo_asset_owner_id')

      if (assetId && !isIdTouched && assetsById?.length) {
        const selectedEvent = assetsById.find(
          (pu) => pu.metadata.cargo_asset_owner_id === assetId
        )

        if (selectedEvent) {
          populateCargoAssetFields(selectedEvent)
        }
      }

      return
    }

    const isAssetLpnTouched = dirtyFields.cargo_asset_license_plate_number
    const assetLpn = watch('cargo_asset_license_plate_number')

    if (assetLpn && !isAssetLpnTouched && assetsByLpn?.length) {
      const selectedEvent = assetsByLpn.find(
        (pu) =>
          pu.metadata.power_unit_license_plate_number === assetLpn &&
          pu.metadata.power_unit_license_plate_state === assetLpnState
      )

      if (selectedEvent) {
        populateCargoAssetFields(selectedEvent)
      }
    }
  }

  useEffect(() => {
    fulfillInitialFormValues('id')
  }, [assetsById])

  useEffect(() => {
    fulfillInitialFormValues('lpn')
  }, [assetsByLpn])

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          <ReadOnlyFormValue
            title="Asset Type"
            value={
              assetType ? cargoAssetTypesFriendlyNames[assetType] : undefined
            }
          />

          <Row gap={16}>
            <Controller
              name="cargo_asset_owner_id"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  required
                  label="ID"
                  name={field.name}
                  inputValue={field.value}
                  options={assetsById || []}
                  loading={idsLoading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onInputChange={(e, newValue) => {
                    field.onChange(newValue)
                  }}
                  getOptionLabel={(option: CargoAsset) =>
                    option?.metadata?.cargo_asset_owner_id || ''
                  }
                  isOptionEqualToValue={(option: CargoAsset) =>
                    option.id === selectedEventId &&
                    option.metadata.cargo_asset_owner_id === field.value
                  }
                  onChange={(value: CargoAsset) => {
                    populateCargoAssetFields(value)
                  }}
                  inputProps={{
                    uppercase: true
                  }}
                />
              )}
            />

            <ReadOnlyFormValue title="Carrier" value={assetCarrier} />
          </Row>
        </>
      )}

      <Row gap={16}>
        <Controller
          name="load_status"
          control={control}
          render={({ field, fieldState }) => (
            <Select
              required
              name={field.name}
              label="Load Status"
              value={field.value}
              options={loadStatusSelectOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={field.onChange}
            />
          )}
        />

        {!isEmpty && (
          <Input
            {...register('shipment_number')}
            uppercase
            fullWidth
            label="Outbound Ref #"
            error={!!errors.shipment_number}
            helperText={errors.shipment_number?.message}
          />
        )}
      </Row>

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Controller
            name="seal_numbers"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                fullWidth
                type="multiple"
                label="Seal #"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || SEAL_NUMBER_HELPER}
              />
            )}
          />

          <Checkbox
            value={sealMatchesPW}
            label="The seal matches the paperwork "
            onChange={() => setValue('seal_matchPW', !sealMatchesPW)}
          />
        </Col>
      )}

      {isChassisVisible && (
        <ReadOnlyFormValue title="Chassis ID" value={chassisName} />
      )}

      {!isMinimized && (
        <Row gap={16}>
          <Controller
            name="cargo_asset_license_plate_number"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                label="LPN"
                name={field.name}
                inputValue={field.value}
                options={assetsByLpn || []}
                loading={lpnLoading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onInputChange={(e, newValue) => {
                  field.onChange(newValue)
                }}
                getOptionLabel={(option: CargoAsset) =>
                  option?.metadata?.cargo_asset_license_plate_number || ''
                }
                customOptionLabel={({
                  metadata: {
                    cargo_asset_license_plate_state,
                    cargo_asset_license_plate_number
                  }
                }: CargoAsset) =>
                  cargo_asset_license_plate_state
                    ? `${cargo_asset_license_plate_number} (${cargo_asset_license_plate_state})`
                    : cargo_asset_license_plate_number
                }
                isOptionEqualToValue={(option: CargoAsset) =>
                  option.id === selectedEventId &&
                  option.metadata.cargo_asset_license_plate_number ===
                    field.value
                }
                onChange={(value: CargoAsset) => {
                  populateCargoAssetFields(value)
                }}
                inputProps={{
                  uppercase: true
                }}
              />
            )}
          />

          <ReadOnlyFormValue
            title="LPN State/Region"
            value={
              assetLpnState
                ? userFriendlyState({ assetLpnState }, 'assetLpnState')
                : undefined
            }
          />
        </Row>
      )}
    </Col>
  )
}

export default CheckOutCargoAssetFormSection
