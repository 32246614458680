import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import { Autocomplete, Col, ReadOnlyFormValue, Row } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { useGetPowerUnitsOnsiteQuery } from '@/features/gate/api'
import { PowerUnitOnsite } from '@/features/gate/types'
import { CheckOutSchema } from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import {
  fuelTypesFriendlyNames,
  powerUnitTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import { userFriendlyState } from '@/utils/table'

interface IProps {
  siteId: string
  orgId: string
}

const CheckOutPowerUnitFormSection: FC<IProps> = (props) => {
  const { siteId, orgId } = props

  const {
    watch,
    control,
    setValue,
    trigger,
    formState: { dirtyFields }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const [showReadOnlyFields, setShowReadOnlyFields] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(
    undefined
  )

  const idDebounce = useDebounce(watch('power_unit_owner_id'), 800)
  const lpnDebounce = useDebounce(watch('power_unit_license_plate_number'), 800)

  const { data: powerUnitsById, isFetching: idsLoading } =
    useGetPowerUnitsOnsiteQuery({
      site_id: siteId,
      org_id: orgId,
      type: 'id',
      power_unit_owner_id_prefix: idDebounce
    })

  const { data: powerUnitsByLpn, isFetching: lpnLoading } =
    useGetPowerUnitsOnsiteQuery({
      site_id: siteId,
      org_id: orgId,
      type: 'lpn',
      power_unit_lpn_prefix: lpnDebounce
    })

  const lpnState = watch('power_unit_license_plate_state')
  const puType = watch('power_unit_type')
  const usDOT = watch('power_unit_carrier_usdot')
  const operatingCarrier = watch('power_unit_carrier_name')
  const puClass = watch('power_unit_weight_class')
  const puFuelType = watch('power_unit_fuel_type')
  const puVIN = watch('power_unit_vin')
  const account = watch('account_name')

  const toggleDetails = () => {
    setShowReadOnlyFields(!showReadOnlyFields)

    if (showReadOnlyFields) {
      setAnimationInProgress(true)
    }
  }

  const populatePowerUnitFields = (
    selectedEvent: PowerUnitOnsite | undefined
  ) => {
    setSelectedEventId(selectedEvent?.id)

    setValue(
      'appointment_type',
      selectedEvent
        ? selectedEvent.metadata.appointment_type
        : AppointmentTypes.DROP
    )
    setValue(
      'power_unit_type',
      selectedEvent ? selectedEvent.metadata.power_unit_type : undefined
    )
    setValue(
      'power_unit_owner_id',
      selectedEvent ? selectedEvent.metadata.power_unit_owner_id : '',
      { shouldValidate: true }
    )
    setValue(
      'power_unit_license_plate_number',
      selectedEvent
        ? selectedEvent.metadata.power_unit_license_plate_number
        : '',
      { shouldValidate: true }
    )
    setValue(
      'power_unit_license_plate_state',
      selectedEvent ? selectedEvent.metadata.power_unit_license_plate_state : ''
    )
    setValue(
      'power_unit_carrier_usdot',
      selectedEvent ? selectedEvent.metadata.power_unit_carrier_usdot : ''
    )
    setValue(
      'power_unit_carrier_name',
      selectedEvent ? selectedEvent.metadata.power_unit_carrier_name : ''
    )
    setValue(
      'power_unit_weight_class',
      selectedEvent ? selectedEvent.metadata.power_unit_weight_class : undefined
    )
    setValue(
      'power_unit_fuel_type',
      selectedEvent ? selectedEvent.metadata.power_unit_fuel_type : undefined
    )
    setValue(
      'power_unit_vin',
      selectedEvent ? selectedEvent.metadata.power_unit_vin : ''
    )
    setValue(
      'account_name',
      selectedEvent ? selectedEvent.metadata.account_name?.[0] : ''
    )

    setValue(
      'driver_first_name',
      selectedEvent ? selectedEvent.metadata.driver_first_name : ''
    )
    setValue(
      'driver_last_name',
      selectedEvent ? selectedEvent.metadata.driver_last_name : ''
    )
    setValue(
      'driver_license_number',
      selectedEvent ? selectedEvent.metadata.driver_license_number : ''
    )
    setValue(
      'driver_license_state',
      selectedEvent ? selectedEvent.metadata.driver_license_state : ''
    )
    setValue(
      'driver_phone_number',
      selectedEvent ? selectedEvent.metadata.driver_phone_number : ''
    )

    trigger('power_unit_owner_id')
    trigger('power_unit_license_plate_number')
  }

  const fulfillInitialFormValues = (type: 'id' | 'lpn') => {
    if (type === 'id') {
      const isOwnerIdTouched = dirtyFields.power_unit_owner_id
      const ownerId = watch('power_unit_owner_id')

      if (ownerId && !isOwnerIdTouched && powerUnitsById?.length) {
        const selectedEvent = powerUnitsById.find(
          (pu) => pu.metadata.power_unit_owner_id === ownerId
        )

        if (selectedEvent) {
          populatePowerUnitFields(selectedEvent)
        }
      }

      return
    }

    const isLpnTouched = dirtyFields.power_unit_license_plate_number
    const lpn = watch('power_unit_license_plate_number')

    if (lpn && !isLpnTouched && powerUnitsByLpn?.length) {
      const selectedEvent = powerUnitsByLpn.find(
        (pu) =>
          pu.metadata.power_unit_license_plate_number === lpn &&
          pu.metadata.power_unit_license_plate_state === lpnState
      )

      if (selectedEvent) {
        populatePowerUnitFields(selectedEvent)
      }
    }
  }

  useEffect(() => {
    fulfillInitialFormValues('id')
  }, [powerUnitsById])

  useEffect(() => {
    fulfillInitialFormValues('lpn')
  }, [powerUnitsByLpn])

  return (
    <Col items="stretch" gap={20}>
      <ReadOnlyFormValue
        title="Type"
        value={puType ? powerUnitTypesFriendlyNames[puType] : undefined}
      />

      <Controller
        name="power_unit_owner_id"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            label="ID"
            name={field.name}
            inputValue={field.value}
            options={powerUnitsById || []}
            loading={idsLoading}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            onInputChange={(e, newValue) => field.onChange(newValue)}
            getOptionLabel={(option: PowerUnitOnsite) =>
              option?.metadata?.power_unit_owner_id || ''
            }
            isOptionEqualToValue={(option: PowerUnitOnsite) =>
              option.id === selectedEventId
            }
            onChange={(value: PowerUnitOnsite) => {
              populatePowerUnitFields(value)
            }}
            inputProps={{
              uppercase: true
            }}
          />
        )}
      />

      <Row gap={16}>
        <Controller
          name="power_unit_license_plate_number"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              required
              label="LPN"
              name={field.name}
              inputValue={field.value}
              options={powerUnitsByLpn || []}
              loading={lpnLoading}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onInputChange={(e, newValue) => field.onChange(newValue)}
              getOptionLabel={(option: PowerUnitOnsite) =>
                option?.metadata?.power_unit_license_plate_number || ''
              }
              customOptionLabel={(option: PowerUnitOnsite) =>
                `${option.metadata.power_unit_license_plate_number} (${option.metadata.power_unit_license_plate_state})`
              }
              isOptionEqualToValue={(option: PowerUnitOnsite) =>
                option.id === selectedEventId
              }
              onChange={(value: PowerUnitOnsite) => {
                populatePowerUnitFields(value)
              }}
              inputProps={{
                uppercase: true
              }}
            />
          )}
        />

        <ReadOnlyFormValue
          title="LPN State/Region"
          value={
            lpnState ? userFriendlyState({ lpnState }, 'lpnState') : undefined
          }
        />
      </Row>

      <Collapse
        in={showReadOnlyFields}
        unmountOnExit
        onExited={() => setAnimationInProgress(false)}
        className={clsx(
          animationInProgress && '!tw-mt-[-20px] tw-relative tw-top-[20px]'
        )}
      >
        <Col gap={20}>
          <Row gap={16}>
            <ReadOnlyFormValue title="US DOT #" value={usDOT} />
            <ReadOnlyFormValue title="Carrier" value={operatingCarrier} />
          </Row>

          <Row gap={16}>
            <ReadOnlyFormValue
              title="Class"
              value={puClass ? weightClassesFriendlyNames[puClass] : undefined}
            />
            <ReadOnlyFormValue
              title="Fuel Type"
              value={
                puFuelType ? fuelTypesFriendlyNames[puFuelType] : undefined
              }
            />
          </Row>

          <Row gap={16}>
            <ReadOnlyFormValue title="VIN" value={puVIN} />
            <ReadOnlyFormValue title="Account" value={account?.toString()} />
          </Row>
        </Col>
      </Collapse>

      <ToggleDetailsButton
        showDetails={showReadOnlyFields}
        className="tw-self-start !tw-px-0"
        onClick={toggleDetails}
      />
    </Col>
  )
}

export default CheckOutPowerUnitFormSection
