import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Autocomplete,
  Col,
  Input,
  ReadOnlyFormValue,
  Row
} from '@/components/atoms'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'

interface IProps {
  readOnly?: boolean
}

interface IDriverDetailsFormValues {
  driver_first_name: string
  driver_last_name: string
  driver_license_number: string
  driver_phone_number: string | undefined
  driver_license_state: string
}

const DriverDetailsFormSection: FC<IProps> = (props) => {
  const { readOnly } = props

  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useFormContext<IDriverDetailsFormValues>()

  const firstName = watch('driver_first_name')
  const lastName = watch('driver_last_name')

  const driverId = watch('driver_license_number')
  const driverPhone = watch('driver_phone_number')

  const driverLicenseState = watch('driver_license_state')

  if (readOnly) {
    return (
      <Col gap={20}>
        <Row gap={16}>
          <ReadOnlyFormValue title="First Name" value={firstName} />
          <ReadOnlyFormValue title="Last Name" value={lastName} />
        </Row>

        <Row gap={16}>
          <ReadOnlyFormValue title="Driver ID" value={driverId} />
          <ReadOnlyFormValue
            title="Driver ID State/Region"
            value={driverLicenseState}
          />
        </Row>

        <ReadOnlyFormValue title="Driver Phone" value={driverPhone} />
      </Col>
    )
  }

  return (
    <Col gap={20}>
      <Row gap={16}>
        <Input
          {...register('driver_first_name')}
          required
          fullWidth
          label="First Name"
          error={!!errors.driver_first_name}
          helperText={errors.driver_first_name?.message}
        />

        <Input
          {...register('driver_last_name')}
          required
          fullWidth
          label="Last Name"
          error={!!errors.driver_last_name}
          helperText={errors.driver_last_name?.message}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('driver_license_number')}
          required
          uppercase
          fullWidth
          label="Driver ID"
          error={!!errors.driver_license_number}
          helperText={errors.driver_license_number?.message}
        />

        <Controller
          name="driver_license_state"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              required
              freeSolo={false}
              label="Driver ID State/Region"
              options={statesOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={(value: ISelectOption) =>
                field.onChange(value?.id || '')
              }
              isOptionEqualToValue={(option: ISelectOption) =>
                option.id === field.value
              }
            />
          )}
        />
      </Row>

      <Controller
        name="driver_phone_number"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            fullWidth
            type="phone"
            label="Driver Phone"
            error={!!errors.driver_phone_number}
            helperText={errors.driver_phone_number?.message}
          />
        )}
      />
    </Col>
  )
}

export default DriverDetailsFormSection
