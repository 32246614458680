import { FC } from 'react'

import { Col, ReadOnlyFormValue, Row } from '@/components/atoms'
import { CargoAsset } from '@/features/yard/types'

interface IProps {
  item: CargoAsset
}

const PowerUnitAndDriverDetails: FC<IProps> = (props) => {
  const { item } = props
  const { metadata } = item

  const {
    power_unit_owner_id,
    driver_first_name,
    driver_last_name,
    driver_license_number,
    driver_phone_number,
    driver_license_state,
    power_unit_carrier_name
  } = metadata

  return (
    <Col gap={20}>
      <ReadOnlyFormValue title="ID" value={power_unit_owner_id} />

      <ReadOnlyFormValue
        title="Driver Name"
        value={`${driver_first_name} ${driver_last_name}`}
      />

      <Row gap={16}>
        <ReadOnlyFormValue
          title="Driver License #"
          value={`${driver_license_number} (${driver_license_state})`}
        />
        <ReadOnlyFormValue title="Driver Phone #" value={driver_phone_number} />
      </Row>

      <ReadOnlyFormValue
        title="Operating Carrier"
        value={power_unit_carrier_name}
      />
    </Col>
  )
}

export default PowerUnitAndDriverDetails
