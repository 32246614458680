import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible,
  isUsdotAndCarrierRequired
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

const validations = {
  isNotMinimized: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType) &&
      !isCargoAssetMinimized(puType)
  },

  isVisible: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType)
  },

  isNotEmpty: {
    keys: ['appointment_type', 'power_unit_type', 'load_status'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      isCargoAssetVisible(appointmentType, puType) && !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: ['appointment_type', 'power_unit_type', 'cargo_asset_asset_type'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      assetType: CargoAssetTypes
    ) =>
      isCargoAssetVisible(appointmentType, puType) &&
      isChassisIdVisible(puType, assetType)
  }
}

const CheckInSchema = yup.object({
  // General Section
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  // Power Unit Section
  power_unit_owner_id: yup.string().max(50, 'Max length is 50'),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .required('Type is required'),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup.string().required('State is required'),
  power_unit_carrier_name: yup.string().when('appointment_type', {
    is: isUsdotAndCarrierRequired,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  power_unit_carrier_usdot: yup.string().when('appointment_type', {
    is: isUsdotAndCarrierRequired,
    then: (schema) =>
      schema
        .test(
          'len',
          'Min length is 3',
          (val) => !!val && val?.toString()?.length >= 3
        )
        .test(
          'len',
          'Max length is 20',
          (val) => !!val && val?.toString()?.length <= 20
        )
        .required('US DOT # is required'),
    otherwise: (schema) => schema
  }),
  power_unit_vin: yup.string().max(20, 'Max length is 20'),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses), 'Class is required')
    .required('Class is required'),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes), 'Fuel Type is required')
    .required('Fuel Type is required'),
  account_name: yup.string(),

  // Driver Section
  driver_first_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('First Name is required'),
  driver_last_name: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Last Name is required'),
  driver_license_number: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),
  driver_phone_number: yup
    .string()
    .test('format', 'Not valid phone number', (val) =>
      val
        ? matchIsValidTel(val, {
            onlyCountries: ['US', 'MX', 'CA']
          })
        : true
    )
    .notRequired(),
  driver_license_state: yup.string().required('State is required'),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) =>
        schema
          .oneOf(Object.values(CargoAssetTypes))
          .required('Asset type is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('ID is required'),
    otherwise: (schema) => schema
  }),
  cargo_asset_carrier_name: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  chassis_id: yup.string().when(validations.isChassisVisible.keys, {
    is: validations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup.string(),
  load_status: yup.mixed<LoadTypes>().when(validations.isVisible.keys, {
    is: validations.isVisible.validate,
    then: (schema) =>
      schema
        .oneOf(Object.values(LoadTypes))
        .required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipment_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired(),

  inspection_completed: yup.boolean().notRequired()
})

export default CheckInSchema
