import { baseApi, invalidateTagsOnSuccess, withParams } from '@/api'

import endpoints from './endpoints'
import {
  CreateGateTransactionRequest,
  CreateGateTransactionResponse,
  DeleteQueueEventRequest,
  FetchAccountsRequest,
  FetchAccountsResponse,
  FetchGateDriversRequest,
  FetchGateDriversResponse,
  FetchGatesRequest,
  FetchGatesResponse,
  FetchGateTransactionsRequest,
  FetchGateTransactionsResponse,
  FetchLanesRequest,
  FetchLanesResponse,
  FetchPresignedUrlRequest,
  FetchPresignedUrlResponse,
  FetchQueueEventsRequest,
  FetchQueueEventsResponse,
  GetCargoAssetsOnSiteRequest,
  GetCargoAssetsOnSiteResponse,
  GetCarrierRequest,
  GetCarrierResponse,
  GetCarriersListRequest,
  GetCarriersListResponse,
  GetClassAndFuelDetailsRequest,
  GetClassAndFuelDetailsResponse,
  GetPowerUnitsOnSiteRequest,
  GetPowerUnitsOnSiteResponse,
  GetVinRequest,
  GetVinResponse,
  UpdateGateTransactionRequest,
  UpdateGateTransactionResponse
} from '@/features/gate/api/types'
import { ISelectOption } from '@/types/interfaces/ui'
import { DefaultApiResponse } from '@/types/interfaces/api'
import { PowerUnitOnsite } from '@/features/gate/types'
import { CargoAsset } from '@/features/yard/types'

export const gateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGates: builder.query<FetchGatesResponse, FetchGatesRequest>({
      query: ({ org_id, site_id }) => {
        return withParams(endpoints.GET_GATES, { org_id, site_id })
      }
    }),

    fetchLanes: builder.query<FetchLanesResponse, FetchLanesRequest>({
      query: ({ org_id, site_id, gate_id }) => {
        return withParams(endpoints.GET_LANES, { org_id, site_id, gate_id })
      }
    }),

    fetchGateTransactions: builder.query<
      FetchGateTransactionsResponse,
      FetchGateTransactionsRequest
    >({
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_GATE_TRANSACTIONS, { org_id, site_id }),
        params
      }),
      keepUnusedDataFor: 0
    }),

    fetchGateDrivers: builder.query<
      FetchGateDriversResponse,
      FetchGateDriversRequest
    >({
      providesTags: ['PowerUnit'],
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_DRIVERS, { org_id, site_id }),
        params
      }),
      keepUnusedDataFor: 0
    }),

    fetchAccounts: builder.query<ISelectOption[], FetchAccountsRequest>({
      query: ({ org_id, site_id }) => ({
        url: withParams(endpoints.GET_ACCOUNTS, { org_id, site_id })
      }),

      keepUnusedDataFor: 300,

      transformResponse: (response: FetchAccountsResponse) => {
        const accounts = response?.data?.accounts || []

        return accounts.map((account) => ({
          id: account,
          label: account
        }))
      }
    }),

    fetchQueueEvents: builder.query<
      FetchQueueEventsResponse,
      FetchQueueEventsRequest
    >({
      providesTags: ['Event'],
      query: ({ org_id, lane_id, ...params }) => ({
        url: withParams(endpoints.GET_QUEUE_EVENTS, {
          org_id,
          lane_id
        }),
        params
      })
    }),

    deleteQueueEvent: builder.mutation<
      DefaultApiResponse,
      DeleteQueueEventRequest
    >({
      invalidatesTags: invalidateTagsOnSuccess(['Event']),
      query: ({ reason, ...urlParams }) => ({
        url: withParams(endpoints.DELETE_QUEUE_EVENT, urlParams),
        params: { reason },
        method: 'DELETE'
      })
    }),

    getPresignedUrl: builder.query<string, FetchPresignedUrlRequest>({
      query: ({ org_id, site_id, event_id }) => ({
        url: withParams(endpoints.GET_PRESIGNED_URL, {
          org_id,
          site_id,
          event_id
        })
      }),
      keepUnusedDataFor: 600,

      transformResponse: (response: FetchPresignedUrlResponse) =>
        response?.data?.url || ''
    }),

    createGateTransaction: builder.mutation<
      CreateGateTransactionResponse,
      CreateGateTransactionRequest
    >({
      invalidatesTags: invalidateTagsOnSuccess(['Event']),
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.CREATE_GATE_TRANSACTION, params),
          method: 'POST',
          body
        }
      }
    }),

    updateGateTransaction: builder.mutation<
      UpdateGateTransactionResponse,
      UpdateGateTransactionRequest
    >({
      invalidatesTags: invalidateTagsOnSuccess(['PowerUnit', 'CargoAsset']),
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.UPDATE_GATE_TRANSACTION, params),
          method: 'PATCH',
          body
        }
      }
    }),

    getCarrier: builder.query<GetCarrierResponse, GetCarrierRequest>({
      query: ({ org_id, us_dot }) => {
        return {
          url: withParams(endpoints.GET_CARRIER, { org_id }),
          params: { us_dot }
        }
      }
    }),

    getCarriers: builder.query<GetCarriersListResponse, GetCarriersListRequest>(
      {
        query: ({ org_id, partial_name }) => {
          return {
            url: withParams(endpoints.GET_US_DOT, { org_id }),
            params: { partial_name }
          }
        }
      }
    ),

    getVin: builder.query<GetVinResponse, GetVinRequest>({
      query: ({ org_id, license_plate_number, state }) => {
        return {
          url: withParams(endpoints.GET_VIN_NUMBER, { org_id }),
          params: { license_plate_number, state }
        }
      }
    }),

    getClassAndFuel: builder.query<
      GetClassAndFuelDetailsResponse,
      GetClassAndFuelDetailsRequest
    >({
      query: ({ org_id, vin }) => {
        return {
          url: withParams(endpoints.GET_CLASS_AND_FUEL_DETAILS, { org_id }),
          params: { vin }
        }
      }
    }),

    getPowerUnitsOnsite: builder.query<
      PowerUnitOnsite[],
      GetPowerUnitsOnSiteRequest
    >({
      providesTags: ['PowerUnit'],
      query: ({ org_id, site_id, type, ...params }) => {
        return {
          url: withParams(
            type === 'id'
              ? endpoints.GET_POWER_UNITS_ONSITE_BY_ID
              : endpoints.GET_POWER_UNITS_ONSITE_BY_LPN,
            {
              org_id,
              site_id
            }
          ),
          params
        }
      },
      keepUnusedDataFor: 0,

      transformResponse: (response: GetPowerUnitsOnSiteResponse) => {
        return response?.data || []
      }
    }),

    getCargoAssetsOnsite: builder.query<
      CargoAsset[],
      GetCargoAssetsOnSiteRequest
    >({
      providesTags: ['CargoAsset'],
      query: ({ org_id, site_id, type, ...params }) => {
        return {
          url: withParams(
            type === 'id'
              ? endpoints.GET_CARGO_ASSETS_ONSITE_BY_ID
              : endpoints.GET_CARGO_ASSETS_ONSITE_BY_LPN,
            {
              org_id,
              site_id
            }
          ),
          params
        }
      },
      keepUnusedDataFor: 0,

      transformResponse: (
        response: GetCargoAssetsOnSiteResponse,
        meta,
        arg
      ) => {
        const { type } = arg

        const items = response?.data || []

        return type === 'lpn'
          ? items.filter(
              (item) => !!item.metadata.cargo_asset_license_plate_number
            )
          : items
      }
    })
  })
})

export const {
  useFetchGatesQuery,
  useLazyFetchGatesQuery,
  useFetchLanesQuery,
  useFetchGateDriversQuery,
  useFetchQueueEventsQuery,
  useFetchGateTransactionsQuery,
  useFetchAccountsQuery,
  useGetPresignedUrlQuery,
  useLazyGetCarrierQuery,
  useLazyGetVinQuery,
  useLazyGetClassAndFuelQuery,
  useLazyGetCarriersQuery,
  useGetCarriersQuery,
  useGetCarrierQuery,
  useUpdateGateTransactionMutation,
  useDeleteQueueEventMutation,
  useCreateGateTransactionMutation,
  useGetPowerUnitsOnsiteQuery,
  useGetCargoAssetsOnsiteQuery
} = gateApi
