import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip, Snackbar } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CargoDetailsReadOnly,
  DriverDetailsFormSection,
  DriverDetailsPowerUnitFormSection
} from '@/features/gate'
import {
  useFetchAccountsQuery,
  useGetPresignedUrlQuery,
  useUpdateGateTransactionMutation
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  UpdateGateTransactionRequestBody,
  UpdateGateTransactionRequestParams
} from '@/features/gate/api/types'
import { PowerUnitOnsite } from '@/features/gate/types'
import { DriverDetailsSchema, isAccountVisible } from '@/features/gate/utils'
import { useStore } from '@/store'
import { FuelTypes } from '@/types/enums/transactionDetails'
import { FormReturn } from '@/types/interfaces/forms'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { scrollToFirstFormError } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface IProps extends IModalWithCloseFn {
  item: PowerUnitOnsite
}

const DriverDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { metadata, id, reference_id, lane_id, lane_display_name } = item
  const { mismatch } = metadata

  const { selectedPortal, org } = useStore((store) => store.user)
  const { gate } = useStore((store) => store.gate)

  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [updateGateTransaction, { isLoading }] =
    useUpdateGateTransactionMutation()
  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    { skip: !reference_id }
  )

  const formReturn = useForm<FormReturn<typeof DriverDetailsSchema>>({
    resolver: yupResolver(DriverDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,

    defaultValues: {
      date: new Date(item.created_date),
      appointment_type: item.metadata.appointment_type,

      power_unit_owner_id: item.metadata.power_unit_owner_id,
      power_unit_license_plate_number:
        item.metadata.power_unit_license_plate_number,
      power_unit_license_plate_state:
        item.metadata.power_unit_license_plate_state,
      power_unit_carrier_name: item.metadata.power_unit_carrier_name,

      power_unit_carrier_usdot: item.metadata.power_unit_carrier_usdot,
      power_unit_vin: item.metadata.power_unit_vin,
      power_unit_weight_class: item.metadata.power_unit_weight_class,
      power_unit_fuel_type: item.metadata.power_unit_fuel_type,
      account_name: item.metadata.account_name?.[0],

      driver_first_name: item.metadata.driver_first_name,
      driver_last_name: item.metadata.driver_last_name,
      driver_license_number: item.metadata.driver_license_number,
      driver_phone_number: item.metadata.driver_phone_number,
      driver_license_state: item.metadata.driver_license_state
    }
  })

  const {
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const onSubmit = async (formData: FormReturn<typeof DriverDetailsSchema>) => {
    if (!org_id || !site_id) return

    const {
      appointment_type,

      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_carrier_name,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number,
      driver_license_state
    } = formData

    const bodyMetadata: CreateGateTransactionMetadata = {
      ...item.metadata,
      appointment_type,

      power_unit_owner_id: power_unit_owner_id || '',
      power_unit_license_plate_number,
      power_unit_license_plate_state,

      power_unit_carrier_usdot: power_unit_carrier_usdot || '',
      power_unit_carrier_name: power_unit_carrier_name || '',

      power_unit_vin: power_unit_vin || '',
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_emission_type:
        emissionTypeByFuelMapper[power_unit_fuel_type as FuelTypes],
      account_name: [],

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number: driver_phone_number || '',
      driver_license_state
    }

    if (isAccountVisible(appointment_type)) {
      bodyMetadata.account_name = account_name ? [account_name] : []
    }

    try {
      if (!gate) {
        throw new Error('Gate not found')
      }

      const query: UpdateGateTransactionRequestBody = {
        correlation_id: item.correlation_id,
        metadata: bodyMetadata
      }

      const params: UpdateGateTransactionRequestParams = {
        org_id,
        site_id,
        gate_id: gate.id,
        lane_id
      }

      await updateGateTransaction({
        params,
        body: query
      }).unwrap()

      closeModal()
    } catch (e: any) {
      setErrorMsg(e?.data?.errors?.[0]?.message || 'Something went wrong')
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <Modal
      title="Driver Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button
          action="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          type="primary"
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <Snackbar
          type="error"
          open={!!errorMsg}
          onClose={() => setErrorMsg(null)}
          message={errorMsg}
        />

        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!reference_id}
              img={imageUrl}
              laneName={lane_display_name}
            />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <DriverDetailsPowerUnitFormSection
                orgId={org_id}
                accounts={accounts}
              />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection />
            </FormSection>

            <FormSection title="Cargo Asset">
              <CargoDetailsReadOnly item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default DriverDetailsModal
