import FilterListIcon from '@mui/icons-material/FilterList'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { Popover, Slide } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useRef,
  useState
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Input, Portal, Row, Text } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  DateRangeFilter,
  FilterValues,
  GroupedListFilter,
  ListFilter
} from '@/components/organisms/Table/components'
import { Color } from '@/styles/palette'
import { TableFilterType } from '@/types/enums/table'
import { TextTypes } from '@/types/enums/ui'
import {
  IFilterDateRange,
  IFilterGroupedList,
  IFilterItem,
  IFilterList
} from '@/types/interfaces/table'

import styles from './FilterDropdown.module.scss'

interface IProps {
  isFilterApplied: boolean
}

const FilterDropdown: FC<IProps> = (props) => {
  const { isFilterApplied } = props

  const {
    filters,
    filterValues: values,
    setFilterValues: onUpdate
  } = useTableContext()

  const mainContainerRef = useRef<any>()
  const containerRef = useRef<any>()
  const [, setSearchParams] = useSearchParams()

  const [open, setOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState<
    IFilterItem | undefined
  >()

  const handleClick = () => {
    containerRef.current = mainContainerRef.current

    setOpen(!open)

    if (open) {
      setSelectedFilter(undefined)
    }
  }

  const toggleSpecificFilter = (
    e: MouseEvent<HTMLSpanElement>,
    filter: IFilterItem
  ) => {
    containerRef.current = e.target

    setOpen(true)
    setSelectedFilter(filter)
  }

  const handleClose = () => {
    setOpen(false)

    setTimeout(() => {
      setSelectedFilter(undefined)
    }, 200)
  }

  const handleOptionClick = (filter: IFilterItem) => {
    setSelectedFilter(filter)
  }

  const handleKeyDown = (event: KeyboardEvent, item: IFilterItem) => {
    if (event.key === 'Enter') {
      handleOptionClick(item)
    }
  }

  const onChange = (filterId: string, value: any, close?: boolean) => {
    onUpdate({ ...cloneDeep(values), [filterId]: value })

    if (!value && containerRef.current.id !== 'filters-btn') {
      setOpen(false)
    }

    if (close) {
      handleClose()
    }
  }

  const clearFilters = () => {
    onUpdate({})
    setSearchParams({})
  }

  const FirstStep = (
    <div>
      {filters?.map((item) => (
        <div
          id={item.id}
          key={item.id}
          tabIndex={0}
          className={clsx(styles.filterOption)}
          onClick={() => handleOptionClick(item)}
          onKeyDown={(e) => handleKeyDown(e, item)}
        >
          <Text type={TextTypes.TEXT_MD} color={Color.gray800}>
            {item.name}
          </Text>
        </div>
      ))}
    </div>
  )

  const renderSecondStep = () => {
    if (!selectedFilter) return null

    let Component: ReactNode

    switch (selectedFilter.type) {
      case TableFilterType.DateRange:
        Component = (
          <DateRangeFilter
            filter={selectedFilter as IFilterDateRange}
            value={values?.[selectedFilter.id]}
            onChange={(v, close) => onChange(selectedFilter.id, v, close)}
          />
        )
        break

      case TableFilterType.GroupedList:
        Component = (
          <GroupedListFilter
            filter={selectedFilter as IFilterGroupedList}
            selected={values?.[selectedFilter.id]}
            onChange={(v) => onChange(selectedFilter.id, v)}
          />
        )
        break

      case TableFilterType.Time:
        Component = (
          <div className="tw-px-8">
            <Input
              required
              fullWidth
              name="time"
              type="time"
              hideDayTime
              icon={<TimerOutlinedIcon />}
              value={values?.[selectedFilter.id]}
              onChange={(date) => {
                onChange(selectedFilter.id, date)
              }}
            />
          </div>
        )
        break

      case TableFilterType.List: {
        Component = (
          <ListFilter
            filter={selectedFilter as IFilterList}
            selected={values?.[selectedFilter.id]}
            onChange={(v) => onChange(selectedFilter.id, v)}
          />
        )
        break
      }

      default: {
        break
      }
    }

    return (
      <Slide direction="left" in={!!selectedFilter} mountOnEnter unmountOnExit>
        <div>{Component}</div>
      </Slide>
    )
  }

  return (
    <Row items="stretch" gap={8} ref={mainContainerRef} id="filters-btn">
      <Portal wrapperId="filters-portal">
        <FilterValues
          onChange={onChange}
          filters={filters as IFilterItem[]}
          values={values}
          toggleSpecificFilter={toggleSpecificFilter}
        />
      </Portal>

      <Row items="stretch" gap={8} className="tw-self-stretch">
        <Button
          small
          keepFocus={open}
          type="outlined"
          onClick={handleClick}
          aria-describedby="filter-popover"
          startIcon={<FilterListIcon />}
        >
          Filter
        </Button>

        {isFilterApplied && (
          <Button small type="text" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </Row>

      <Popover
        id="filter-popover"
        open={open}
        anchorEl={containerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: styles.filterDropdown }}
      >
        {selectedFilter ? renderSecondStep() : FirstStep}
      </Popover>
    </Row>
  )
}

export default FilterDropdown
