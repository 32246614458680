import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo, useState } from 'react'

import { Button, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { CargoAssetDetailsModal } from '@/features/yard'
import { useFetchCargoAssetsQuery } from '@/features/yard/api'
import {
  cargoAssetsFilters,
  cargoAssetsTableShape
} from '@/features/yard/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import { PaginationQuery, SearchQuery } from '@/types/interfaces/api'

const CargoAssetTemplate = () => {
  const [selectedItem, setSelectedItem] = useState<any>()

  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [{ current_page, items_per_page, search_query }, setQuery] = useState<
    PaginationQuery & SearchQuery
  >({
    current_page: 1,
    items_per_page: PAGINATION_ITEMS_PER_PAGE,
    search_query: ''
  })

  const { data, isFetching } = useFetchCargoAssetsQuery({
    org_id,
    site_id,
    current_page,
    items_per_page,
    search_query
  })

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const { visible, openModal, closeModal } = useModal(false)
  const filters = useMemo(() => cargoAssetsFilters(accounts), [accounts])

  const handleAction = (action: TableActions, row: any) => {
    switch (action) {
      case TableActions.RowClick: {
        setSelectedItem(row)
        openModal()
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <CargoAssetDetailsModal item={selectedItem} closeModal={closeModal} />
      )}

      <WithHeaderTemplate title="Cargo Assets On Site">
        <HeaderActionPortal>
          <Button type="outlined" startIcon={<IosShareIcon />}>
            Export
          </Button>
        </HeaderActionPortal>

        <div className="tw-flex-1 tw-flex">
          <Table
            clickable
            idAccessor="id"
            name="cargo-assets"
            rows={data?.data?.assets || []}
            loading={isFetching}
            handleAction={handleAction}
            filters={filters}
            columns={cargoAssetsTableShape}
            searchPlaceholder="Search Cargo Asset"
            perPage={items_per_page}
            currentPage={current_page}
            totalPages={data?.pagination?.total_pages}
            totalItems={data?.pagination?.total_items_count}
            onPageChange={(page) =>
              setQuery((prev) => ({ ...prev, current_page: page }))
            }
            onSearch={(value) => {
              setQuery((prev) => ({ ...prev, search_query: value }))
            }}
            noResultsMessage={{
              title: 'No cargo assets on site',
              description:
                'Cargo assets that have Checked-In, but have not Checked-Out will appear here.'
            }}
          />
        </div>
      </WithHeaderTemplate>
    </>
  )
}

export default CargoAssetTemplate
