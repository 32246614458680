import { CircularProgress } from '@mui/material'
import { FC, ReactNode } from 'react'

import { Col, Text } from '@/components/atoms'
import { ImageWithPreview } from '@/components/molecules'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './FormAside.module.scss'

interface IProps {
  img: string | undefined
  laneName: string
  showImage: boolean
  Action?: ReactNode
}

const FormAside: FC<IProps> = (props) => {
  const { img, laneName, showImage, Action } = props

  const Image = img ? (
    <ImageWithPreview
      imageUrl={img}
      alt="Check-In"
      title={laneName}
      className={styles.truckImg}
    />
  ) : (
    <Col items="center" justify="center" className={styles.truckImg}>
      <CircularProgress color="secondary" />
    </Col>
  )

  return (
    <>
      {showImage ? Image : undefined}

      <Col items="start" className={styles.metadata} gap={16}>
        <Col gap={5}>
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Lane
          </Text>
          <Text
            type={TextTypes.TEXT_LG}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {laneName}
          </Text>
        </Col>

        {Action}
      </Col>
    </>
  )
}

export default FormAside
