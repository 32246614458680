import {
  FetchBaseQueryError,
  RootState,
  TagDescription
} from '@reduxjs/toolkit/query'

import { Dictionary } from '@/types/interfaces/common'

export function withQuery(
  url: string,
  queries: Record<string, string | number | undefined>
) {
  const queryArr = Object.entries(queries)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)

  const queryString = queryArr.join('&')

  return `${url}?${queryString}`
}

export function withParams(endpoint: string, params: Dictionary<string>) {
  let urlWithPatchedParams = endpoint

  Object.keys(params).forEach((key: string) => {
    if (urlWithPatchedParams.includes(key)) {
      urlWithPatchedParams = urlWithPatchedParams.replace(
        `:${key}`,
        params[key]
      )
    }
  })

  return urlWithPatchedParams
}

export const invalidateTagsOnSuccess =
  <Tag extends string>(tags: TagDescription<Tag>[]) =>
  (_result: unknown, error: FetchBaseQueryError | undefined) =>
    error ? [] : tags

export const getAllCacheEntriesByKey = (
  getState: () => RootState<any, any, 'api'>,
  key: string
) => {
  const { queries } = getState().api

  return (
    Object.entries(queries)
      // Search for all cache entries that start with the key
      .filter(([queryKey]) => queryKey.startsWith(key))
      // Return only the query state
      .map(([, query]) => query)
  )
}
