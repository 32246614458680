import { isCargoAssetMinimized } from '@/features/gate/utils'
import {
  CargoAssetTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

const isChassisIdVisible = (
  puType: PowerUnitTypes,
  assetType: CargoAssetTypes | undefined
) => {
  const isMinimized = isCargoAssetMinimized(puType)
  const isTrailer = assetType === CargoAssetTypes.Trailer

  return !isMinimized && !isTrailer
}

export default isChassisIdVisible
