import { FC } from 'react'

import { Checkbox, Col, ReadOnlyFormValue, Row } from '@/components/atoms'
import { PowerUnitOnsite } from '@/features/gate/types'
import {
  cargoAssetTypesFriendlyNames,
  loadTypesFriendlyNames,
  sealMatchesMapper
} from '@/utils/mappers'
import { arrayToString } from '@/utils/table'

interface IProps {
  item: PowerUnitOnsite
}

const CargoDetailsReadOnly: FC<IProps> = (props) => {
  const { item } = props

  const { metadata } = item

  const {
    cargo_asset_asset_type,
    cargo_asset_owner_id,
    cargo_asset_carrier_name,
    load_status,
    shipment_number,
    chassis_id,
    seal_matchPW
  } = metadata

  return (
    <Col gap={20}>
      <ReadOnlyFormValue
        title="Asset Type"
        value={cargoAssetTypesFriendlyNames[cargo_asset_asset_type]}
      />

      <Row gap={16}>
        <ReadOnlyFormValue title="Asset ID" value={cargo_asset_owner_id} />
        <ReadOnlyFormValue
          title="Asset Carrier"
          value={cargo_asset_carrier_name}
        />
      </Row>

      <Row gap={16}>
        <ReadOnlyFormValue
          title="Arriving Load Status"
          value={loadTypesFriendlyNames[load_status]}
        />
        <ReadOnlyFormValue
          title="Arriving Shipment #"
          value={shipment_number}
        />
      </Row>

      <Col gap={4}>
        <ReadOnlyFormValue
          title="Seal #"
          value={arrayToString(metadata, 'seal_numbers')}
        />
        <Checkbox
          disabled
          value={sealMatchesMapper[seal_matchPW]}
          label="The seal matches the paperwork "
        />
      </Col>

      <ReadOnlyFormValue title="Chassis ID" value={chassis_id} />
    </Col>
  )
}

export default CargoDetailsReadOnly
