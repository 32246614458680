import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import { InputLabel } from '@mui/material'
import clsx from 'clsx'
import React, { FC } from 'react'

import { ReactComponent as BobtailTruck } from '@/assets/icons/bobtail_truck.svg'
import { ReactComponent as DropTruck } from '@/assets/icons/drop_truck.svg'
import { Col, Row, Text } from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { Color } from '@/styles/palette'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { appointmentTypesFriendlyNames } from '@/utils/mappers'

import styles from './AppointmentTypeSelector.module.scss'

interface IProps {
  value: AppointmentTypes | undefined
  onChange: (value: AppointmentTypes) => void
}

const AppointmentTypeSelector: FC<IProps> = (props) => {
  const { value, onChange } = props
  const { inputLabelClasses } = classes

  const items = [
    { id: AppointmentTypes.DROP, icon: <DropTruck /> },
    { id: AppointmentTypes.LIVE, icon: <LocalShippingOutlinedIcon /> },
    { id: AppointmentTypes.Bobtail, icon: <BobtailTruck /> },
    { id: AppointmentTypes.Visitor, icon: <AirportShuttleOutlinedIcon /> }
  ]

  return (
    <Col>
      <InputLabel
        required
        focused
        shrink
        variant="standard"
        classes={inputLabelClasses}
      >
        Appointment Type
      </InputLabel>

      <div className={styles.items}>
        {items.map((item) => (
          <Row
            gap={4}
            key={item.id}
            items="center"
            tabIndex={0}
            onClick={() => onChange(item.id)}
            onKeyDown={(e) => e.key === 'Enter' && onChange(item.id)}
            className={clsx(styles.item, item.id === value && styles.selected)}
          >
            {item.icon}

            <Text
              color={Color.gray700}
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
            >
              {appointmentTypesFriendlyNames[item.id]}
            </Text>
          </Row>
        ))}
      </div>
    </Col>
  )
}

export default AppointmentTypeSelector
