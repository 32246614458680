import { Autocomplete } from '@mui/material'
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent
} from 'react'

import { Chip, Input } from '@/components/atoms'
import { MultipleValuesInputProps } from '@/types/interfaces/ui'

const MultipleValuesInput: ForwardRefRenderFunction<
  HTMLInputElement,
  MultipleValuesInputProps
> = (props) => {
  const {
    id,
    name,
    value = [],
    onChange,
    label,
    fullWidth,
    helperText,
    error,
    required,
    disabled
  } = props

  const { length } = value
  const lastIndex = length ? length - 1 : 0

  const inputValue = length ? value[lastIndex] : ''

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      if (!inputValue) return

      onChange([...value, ''])
    }
  }

  const onInputChange = (e: any, newValue: string) => {
    if (newValue.includes(',')) return

    const updatedValues = length
      ? value.map((v, index) => (index === lastIndex ? newValue : v))
      : [newValue]

    onChange(updatedValues)
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      disableClearable
      forcePopupIcon={false}
      id={id}
      fullWidth={fullWidth}
      open={false}
      value={value}
      options={[]}
      disabled={disabled}
      getOptionLabel={(option: string) => option}
      onKeyDown={onKeyDown}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderTags={(savedValues, getTagProps) =>
        savedValues.map((option, index) =>
          index !== lastIndex ? (
            <Chip
              key={`${index}-${option}`}
              size="sm"
              type="default"
              label={option}
              className="!tw-mr-[5px]"
              onDelete={getTagProps({ index }).onDelete}
            />
          ) : undefined
        )
      }
      renderInput={(params) => (
        <Input
          {...params}
          name={name}
          required={required}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
      onChange={(e, newValue) => onChange(newValue)}
    />
  )
}

export default forwardRef(MultipleValuesInput)
