import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import {
  Autocomplete,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select,
  WarningMessage
} from '@/components/atoms'
import { SuggestedItems } from '@/features/gate'
import {
  useLazyGetCarrierQuery,
  useLazyGetCarriersQuery,
  useLazyGetClassAndFuelQuery,
  useLazyGetVinQuery
} from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import {
  CheckInSchema,
  getMissingFieldNames,
  isAccountVisible,
  isUsdotAndCarrierRequired
} from '@/features/gate/utils'
import { FuelTypes, WeightClasses } from '@/types/enums/transactionDetails'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import {
  classListSelectOptions,
  fuelTypeSelectOptions,
  powerUnitTypeSelectOptions
} from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
  suggestedIds: string[]
  suggestedUsdot: string[]
  orgId: string
}

const CheckInPowerUnitFormSection: FC<IProps> = (props) => {
  const { accounts, suggestedIds, suggestedUsdot, orgId } = props

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const [selectedSuggestedId, setSelectedSuggestedId] = useState<
    string | undefined
  >(watch('power_unit_owner_id'))
  const [selectedSuggestedUsDOT, setSelectedSuggestedUsDOT] = useState<
    string | undefined
  >(watch('power_unit_carrier_usdot'))
  const [carriersList, setCarriersList] = useState<Carrier[]>([])
  const [failedToLoad, setFailedToLoad] = useState({
    vin: false,
    fuelType: false,
    weightClass: false
  })

  const lpnDebounced = useDebounce(
    watch('power_unit_license_plate_number'),
    800
  )
  const lpnState = watch('power_unit_license_plate_state')
  const vinDebounced = useDebounce(watch('power_unit_vin'), 800)
  const usdotDebounce = useDebounce(watch('power_unit_carrier_usdot'), 800)
  const carrierDebounced = useDebounce(watch('power_unit_carrier_name'), 800)

  const [getCarrier, { isFetching: carrierLoading }] = useLazyGetCarrierQuery()
  const [getCarriers, { isFetching: carriersLoading }] =
    useLazyGetCarriersQuery()
  const [getVin, { isFetching: vinLoading }] = useLazyGetVinQuery()
  const [getClassAndFuelType, { isFetching: classAndFuelLoading }] =
    useLazyGetClassAndFuelQuery()

  const powerUnitId = watch('power_unit_owner_id')
  const appointmentType = watch('appointment_type')

  const isUsdotRequired = isUsdotAndCarrierRequired(appointmentType)
  const showAccount = isAccountVisible(appointmentType)

  const onSelectSuggestedItem = (value: string) => {
    setSelectedSuggestedId(value)
    setValue('power_unit_owner_id', value, { shouldValidate: true })
  }

  const onSelectSuggestedUsDOT = (value: string) => {
    setSelectedSuggestedUsDOT(value)
    setValue('power_unit_carrier_usdot', value, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const fetchAndUpdateVin = async () => {
    if (!lpnDebounced || !lpnState || lpnDebounced?.length < 5) return

    await getVin({
      org_id: orgId,
      license_plate_number: lpnDebounced,
      state: lpnState
    }).then((response) => {
      const vin = response?.data?.data?.vin || ''

      if (!vin) {
        setFailedToLoad({
          vin: true,
          fuelType: true,
          weightClass: true
        })

        setValue('power_unit_fuel_type', '' as FuelTypes)
        setValue('power_unit_weight_class', '' as WeightClasses)
      }

      setValue('power_unit_vin', vin, { shouldValidate: !!vin })
    })
  }

  const fetchAndUpdateCarrier = async () => {
    if (!usdotDebounce) return

    // When user select carrier from the list it will automatically
    // replace the usdot number with the selected carrier's usdot number,
    // so we don't need to fetch the carrier details again
    const selectedCarrier = carriersList?.find?.(
      (carrier) =>
        carrier.name === carrierDebounced &&
        carrier.us_dot_number === usdotDebounce
    )

    if (selectedCarrier?.us_dot_number === usdotDebounce) return

    await getCarrier({
      org_id: orgId,
      us_dot: usdotDebounce
    }).then((response) => {
      const carrier = response?.data?.data?.carrier?.name

      setValue('power_unit_carrier_name', carrier || '', {
        shouldValidate: !!carrier
      })

      setValue('cargo_asset_carrier_name', carrier || '', {
        shouldValidate: !!carrier
      })
    })
  }

  const fetchAndUpdateClassAndFuel = async () => {
    if (!vinDebounced || vinDebounced?.length < 10) return

    await getClassAndFuelType({
      org_id: orgId,
      vin: vinDebounced
    }).then((response) => {
      const vehicleClass = response?.data?.data?.vehicle_class as WeightClasses
      const fuelType = response?.data?.data?.fuel_type as FuelTypes

      setFailedToLoad((prev) => ({
        ...prev,
        vin: false,
        fuelType: !fuelType,
        weightClass: !vehicleClass
      }))

      setValue('power_unit_weight_class', vehicleClass || '', {
        shouldValidate: !!vehicleClass
      })
      setValue('power_unit_fuel_type', fuelType || '', {
        shouldValidate: !!fuelType
      })
    })
  }

  const fetchCarriersList = async () => {
    const carriers = await getCarriers({
      org_id: orgId,
      partial_name: carrierDebounced || ''
    })

    setCarriersList(carriers?.data?.data?.carriers || [])
  }

  useEffect(() => {
    if (powerUnitId !== selectedSuggestedId) {
      setSelectedSuggestedId(undefined)
    }
  }, [powerUnitId])

  useEffect(() => {
    fetchAndUpdateClassAndFuel()
  }, [vinDebounced])

  useEffect(() => {
    fetchAndUpdateVin()
  }, [lpnDebounced, lpnState])

  useEffect(() => {
    fetchAndUpdateCarrier()
  }, [usdotDebounce])

  useEffect(() => {
    fetchCarriersList()
  }, [carrierDebounced])

  return (
    <Col items="stretch" gap={20}>
      <Controller
        name="power_unit_type"
        control={control}
        render={({ field, formState }) => (
          <Select
            required
            label="Type"
            name={field.name}
            value={field.value}
            error={!!formState.errors.power_unit_type}
            helperText={formState.errors.power_unit_type?.message}
            options={powerUnitTypeSelectOptions}
            onChange={field.onChange}
          />
        )}
      />

      <Col gap={7}>
        <Controller
          name="power_unit_owner_id"
          control={control}
          render={({ field, formState }) => (
            <Input
              uppercase
              fullWidth
              {...field}
              label="ID"
              error={!!formState.errors.power_unit_owner_id}
              helperText={formState.errors.power_unit_owner_id?.message}
            />
          )}
        />

        {suggestedIds.length > 1 && (
          <SuggestedItems
            suggestedItems={suggestedIds || []}
            selectedId={selectedSuggestedId}
            onChange={onSelectSuggestedItem}
          />
        )}
      </Col>

      <Col gap={8}>
        <Row gap={16}>
          <Controller
            name="power_unit_carrier_usdot"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                required={isUsdotRequired}
                fullWidth
                digitsOnly
                type="number"
                label="US DOT #"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {carrierLoading ? (
            <ReadOnlyFormValue required title="Carrier" loading />
          ) : (
            <Controller
              name="power_unit_carrier_name"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  required={isUsdotRequired}
                  label="Carrier"
                  name={field.name}
                  inputValue={field.value}
                  options={carriersList || []}
                  loading={carriersLoading}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onInputChange={(e, newValue) => field.onChange(newValue)}
                  getOptionLabel={(option: Carrier) => option?.name || ''}
                  customOptionLabel={(option: Carrier) =>
                    `${option.name} (${option.us_dot_number})`
                  }
                  isOptionEqualToValue={(option: Carrier) =>
                    usdotDebounce
                      ? option.name === field.value &&
                        option.us_dot_number === usdotDebounce
                      : option.name === field.value
                  }
                  onChange={(value: Carrier) => {
                    field.onChange(value?.name || '')
                    setValue(
                      'power_unit_carrier_usdot',
                      value?.us_dot_number || ''
                    )
                    setValue('cargo_asset_carrier_name', value?.name || '')
                  }}
                  inputProps={{
                    uppercase: true
                  }}
                />
              )}
            />
          )}
        </Row>

        {suggestedUsdot.length > 1 && (
          <SuggestedItems
            suggestedItems={suggestedUsdot || []}
            selectedId={selectedSuggestedUsDOT}
            onChange={onSelectSuggestedUsDOT}
          />
        )}
      </Col>

      {Object.values(failedToLoad).includes(true) && (
        <WarningMessage>
          Details couldn't be autofilled. Please enter{' '}
          {getMissingFieldNames(failedToLoad)}
        </WarningMessage>
      )}

      <Row gap={16}>
        <Row gap={16} className="tw-w-full">
          <Input
            {...register('power_unit_license_plate_number')}
            required
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.power_unit_license_plate_number}
            helperText={errors.power_unit_license_plate_number?.message}
          />

          <Controller
            name="power_unit_license_plate_state"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                required
                freeSolo={false}
                name={field.name}
                label="LPN State/Region"
                options={statesOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(value: ISelectOption) =>
                  field.onChange(value?.id || '')
                }
                isOptionEqualToValue={(option: ISelectOption) =>
                  option.id === field.value
                }
              />
            )}
          />
        </Row>

        {vinLoading ? (
          <ReadOnlyFormValue loading title="VIN" />
        ) : (
          <Controller
            name="power_unit_vin"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                uppercase
                fullWidth
                label="VIN"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      </Row>

      <Row gap={16}>
        {classAndFuelLoading ? (
          <>
            <ReadOnlyFormValue loading required title="Class" />
            <ReadOnlyFormValue loading required title="Fuel Type" />
          </>
        ) : (
          <>
            <Controller
              name="power_unit_weight_class"
              control={control}
              render={({ field, formState }) => (
                <Select
                  required
                  label="Class"
                  name={field.name}
                  value={field.value}
                  options={classListSelectOptions}
                  error={!!formState.errors.power_unit_weight_class}
                  helperText={formState.errors.power_unit_weight_class?.message}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="power_unit_fuel_type"
              control={control}
              render={({ field, formState }) => (
                <Select
                  required
                  label="Fuel Type"
                  value={field.value}
                  name={field.name}
                  options={fuelTypeSelectOptions}
                  error={!!formState.errors.power_unit_fuel_type}
                  helperText={formState.errors.power_unit_fuel_type?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </>
        )}
      </Row>

      {showAccount && (
        <Controller
          name="account_name"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              freeSolo={false}
              label="Account"
              name={field.name}
              options={accounts}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={(value: ISelectOption) =>
                field.onChange(value?.id || '')
              }
              isOptionEqualToValue={(option: ISelectOption) =>
                option.id === field.value
              }
            />
          )}
        />
      )}
    </Col>
  )
}

export default CheckInPowerUnitFormSection
