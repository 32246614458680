import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip, Snackbar } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  useFetchAccountsQuery,
  useGetPresignedUrlQuery,
  useUpdateGateTransactionMutation
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  UpdateGateTransactionRequestBody,
  UpdateGateTransactionRequestParams
} from '@/features/gate/api/types'
import { SealMatchesPW } from '@/features/gate/enums'
import {
  getSealMatchesPWValue,
  isAccountVisible,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  CargoAssetFormSection,
  PowerUnitAndDriverDetails
} from '@/features/yard'
import { CargoAsset } from '@/features/yard/types'
import { CargoAssetDetailsSchema } from '@/features/yard/utils'
import { useStore } from '@/store'
import { FormReturn } from '@/types/interfaces/forms'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { prepareSealNumbers, scrollToFirstFormError } from '@/utils/helpers'
import { sealMatchesMapper } from '@/utils/mappers'

interface IProps extends IModalWithCloseFn {
  item: CargoAsset
}

const CargoAssetDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { metadata, id, reference_id, lane_display_name, lane_id } = item
  const { mismatch } = metadata

  const { selectedPortal, org } = useStore((store) => store.user)
  const { gate } = useStore((store) => store.gate)

  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const { data: imageUrl } = useGetPresignedUrlQuery(
    {
      org_id,
      site_id,
      event_id: id
    },
    { skip: !reference_id }
  )
  const [updateGateTransaction, { isLoading }] =
    useUpdateGateTransactionMutation()

  const formReturn = useForm<FormReturn<typeof CargoAssetDetailsSchema>>({
    resolver: yupResolver(CargoAssetDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldFocusError: false,

    defaultValues: {
      date: new Date(item.created_date),
      appointment_type: item.metadata.appointment_type,

      power_unit_type: item.metadata.power_unit_type,
      account_name: item.metadata.account_name?.[0],

      cargo_asset_asset_type: item.metadata.cargo_asset_asset_type,
      cargo_asset_owner_id: item.metadata.cargo_asset_owner_id,
      cargo_asset_carrier_name: item.metadata.cargo_asset_carrier_name,
      chassis_id: item.metadata.chassis_id,
      cargo_asset_license_plate_number:
        item.metadata.cargo_asset_license_plate_number,
      cargo_asset_license_plate_state:
        item.metadata.cargo_asset_license_plate_state,
      load_status: item.metadata.load_status,
      shipment_number: item.metadata.shipment_number,
      seal_numbers: item.metadata.seal_numbers || [],
      seal_matchPW: sealMatchesMapper[item.metadata.seal_matchPW],
      inspection_completed: item.metadata.inspection_completed
    }
  })

  const {
    watch,
    handleSubmit,
    formState: { submitCount, errors }
  } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch('appointment_type'),
    watch('power_unit_type')
  )

  const onSubmit = async (
    formData: FormReturn<typeof CargoAssetDetailsSchema>
  ) => {
    if (!org_id || !site_id) return

    const {
      appointment_type,
      power_unit_type,
      account_name,

      cargo_asset_asset_type,
      cargo_asset_owner_id,
      cargo_asset_carrier_name,
      chassis_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      load_status,
      shipment_number,
      seal_numbers,
      seal_matchPW,
      inspection_completed
    } = formData

    const bodyMetadata: CreateGateTransactionMetadata = {
      ...metadata,
      appointment_type,

      cargo_asset_asset_type: '',
      cargo_asset_owner_id: '',
      cargo_asset_carrier_name: '',
      cargo_asset_license_plate_number: '',
      cargo_asset_license_plate_state: '',

      chassis_id: '',
      load_status: '',
      shipment_number: '',
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      inspection_completed: null
    }

    if (isAccountVisible(appointment_type)) {
      bodyMetadata.account_name = account_name ? [account_name] : []
    }

    if (showCargoAsset) {
      bodyMetadata.load_status = load_status || ''
      bodyMetadata.inspection_completed = inspection_completed || null

      if (!isCargoAssetMinimized(power_unit_type)) {
        bodyMetadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number || ''
        bodyMetadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state || ''
        bodyMetadata.cargo_asset_owner_id = cargo_asset_owner_id || ''
        bodyMetadata.cargo_asset_carrier_name = cargo_asset_carrier_name || ''
        bodyMetadata.cargo_asset_asset_type = cargo_asset_asset_type || ''
      }

      if (!isAssetEmpty(load_status)) {
        bodyMetadata.shipment_number = shipment_number || ''
        bodyMetadata.seal_numbers = prepareSealNumbers(seal_numbers)
        bodyMetadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (isChassisIdVisible(power_unit_type, cargo_asset_asset_type)) {
        bodyMetadata.chassis_id = chassis_id || ''
      }
    }

    try {
      if (!gate) {
        throw new Error('Gate not found')
      }

      const query: UpdateGateTransactionRequestBody = {
        correlation_id: item.correlation_id,
        metadata: bodyMetadata
      }

      const params: UpdateGateTransactionRequestParams = {
        org_id,
        site_id,
        gate_id: gate.id,
        lane_id
      }

      await updateGateTransaction({
        params,
        body: query
      }).unwrap()

      closeModal()
    } catch (e: any) {
      setErrorMsg(e?.data?.errors?.[0]?.message || 'Something went wrong')
    }
  }

  useEffect(() => {
    scrollToFirstFormError(errors)
  }, [submitCount])

  return (
    <Modal
      title="Cargo Asset Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button
          action="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          type="primary"
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <Snackbar
          type="error"
          open={!!errorMsg}
          onClose={() => setErrorMsg(null)}
          message={errorMsg}
        />

        <TwoColumnModalWrapper
          leftSide={
            <FormAside
              showImage={!!reference_id}
              img={imageUrl}
              laneName={lane_display_name}
            />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            {showCargoAsset && (
              <FormSection title="Cargo Asset">
                <CargoAssetFormSection orgId={org_id} accounts={accounts} />
              </FormSection>
            )}

            <FormSection title="Power Unit and Driver Details">
              <PowerUnitAndDriverDetails item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CargoAssetDetailsModal
