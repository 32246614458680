import { Autocomplete, CircularProgress, ListItem } from '@mui/material'
import React, { FC, SyntheticEvent, useMemo } from 'react'

import { Input } from '@/components/atoms'
import { TextInputProps } from '@/types/interfaces/ui'

import styles from './Autocomplete.module.scss'

interface IProps {
  onInputChange?: (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: string
  ) => void
  options: any[]
  name?: string
  freeSolo?: boolean
  onChange: (value: any) => void
  getOptionLabel?: (option: any) => string
  isOptionEqualToValue: (option: any, value: any) => boolean
  disabled?: boolean
  required?: boolean
  helperText?: string
  label?: string
  error?: boolean
  fullWidth?: boolean
  className?: string
  loading?: boolean
  inputValue?: string
  customOptionLabel?: (option: any) => string
  inputProps?: Partial<TextInputProps>
}

const CustomAutocomplete: FC<IProps> = (props) => {
  const {
    name,
    label,
    options,
    onChange,
    freeSolo = true,
    disabled,
    required,
    helperText,
    error,
    fullWidth = true,
    className,
    loading = false,
    getOptionLabel,
    onInputChange,
    isOptionEqualToValue,
    customOptionLabel,
    inputValue,
    inputProps = {}
  } = props

  const value = useMemo(
    () =>
      options.find((option) => isOptionEqualToValue(option, inputValue)) ||
      null,
    [isOptionEqualToValue, inputValue, options]
  )

  return (
    <Autocomplete
      forcePopupIcon
      disablePortal
      freeSolo={freeSolo}
      disabled={disabled}
      fullWidth={fullWidth}
      className={className}
      options={options}
      loading={loading}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      inputValue={inputValue}
      value={value}
      ListboxProps={{ className: styles.autocompleteDropdown }}
      renderOption={
        customOptionLabel
          ? (optionProps, option) => (
              <ListItem {...optionProps}>{customOptionLabel(option)}</ListItem>
            )
          : undefined
      }
      renderInput={(params) => (
        <Input
          {...params}
          {...inputProps}
          name={name}
          onChange={params.inputProps.onChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          error={error}
          helperText={helperText}
          required={required}
          label={label}
        />
      )}
      onChange={(e, newValue) => {
        onChange(newValue || undefined)
      }}
    />
  )
}

export default CustomAutocomplete
