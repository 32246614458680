import { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDebounce } from 'usehooks-ts'
import * as yup from 'yup'

import {
  Autocomplete,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/components/atoms'
import {
  useGetCarriersQuery,
  useLazyGetCarrierQuery,
  useLazyGetClassAndFuelQuery,
  useLazyGetVinQuery
} from '@/features/gate/api'
import { Carrier } from '@/features/gate/types'
import {
  DriverDetailsSchema,
  isAccountVisible,
  isUsdotAndCarrierRequired
} from '@/features/gate/utils'
import { FuelTypes, WeightClasses } from '@/types/enums/transactionDetails'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import { classListSelectOptions, fuelTypeSelectOptions } from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
  orgId: string
}

const DriverDetailsPowerUnitFormSection: FC<IProps> = (props) => {
  const { accounts, orgId } = props

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors, isDirty }
  } = useFormContext<yup.InferType<typeof DriverDetailsSchema>>()

  const appointmentType = watch('appointment_type')
  const usdotDebounced = useDebounce(watch('power_unit_carrier_usdot'), 800)
  const carrierDebounced = useDebounce(watch('power_unit_carrier_name'), 800)
  const vinDebounced = useDebounce(watch('power_unit_vin'), 800)
  const lpnDebounced = useDebounce(
    watch('power_unit_license_plate_number'),
    800
  )
  const lpnState = watch('power_unit_license_plate_state')

  const [getCarrier, { isFetching: carrierLoading }] = useLazyGetCarrierQuery()
  const [getVin, { isFetching: vinLoading }] = useLazyGetVinQuery()
  const [getClassAndFuelType, { isFetching: classAndFuelLoading }] =
    useLazyGetClassAndFuelQuery()
  const { data: carriersResponse, isFetching: carriersLoading } =
    useGetCarriersQuery({
      org_id: orgId,
      partial_name: carrierDebounced || ''
    })

  const carriersList = carriersResponse?.data?.carriers || []
  const isUsdotRequired = isUsdotAndCarrierRequired(appointmentType)
  const showAccount = isAccountVisible(appointmentType)

  const fetchAndUpdateCarrier = async () => {
    if (!usdotDebounced) return

    // When user select carrier from the list it will automatically
    // replace the usdot number with the selected carrier's usdot number,
    // so we don't need to fetch the carrier details again
    const selectedCarrier = carriersList?.find?.(
      (carrier) =>
        carrier.name === carrierDebounced &&
        carrier.us_dot_number === usdotDebounced
    )

    if (selectedCarrier?.us_dot_number === usdotDebounced) return

    await getCarrier({
      org_id: orgId,
      us_dot: usdotDebounced
    }).then((response) => {
      const carrier = response?.data?.data?.carrier?.name

      setValue('power_unit_carrier_name', carrier || '', {
        shouldValidate: !!carrier
      })
    })
  }

  const fetchAndUpdateVin = async () => {
    if (!lpnDebounced || !lpnState || lpnDebounced?.length < 5) return

    await getVin({
      org_id: orgId,
      license_plate_number: lpnDebounced,
      state: lpnState
    }).then((response) => {
      const vin = response?.data?.data?.vin || ''

      if (!vin) {
        setValue('power_unit_fuel_type', '' as FuelTypes)
        setValue('power_unit_weight_class', '' as WeightClasses)
      }

      setValue('power_unit_vin', vin, { shouldValidate: !!vin })
    })
  }

  const fetchAndUpdateClassAndFuel = async () => {
    if (!vinDebounced || vinDebounced?.length < 10) return

    await getClassAndFuelType({
      org_id: orgId,
      vin: vinDebounced
    }).then((response) => {
      const vehicleClass = response?.data?.data?.vehicle_class as WeightClasses
      const fuelType = response?.data?.data?.fuel_type as FuelTypes

      setValue('power_unit_weight_class', vehicleClass || '', {
        shouldValidate: !!vehicleClass
      })
      setValue('power_unit_fuel_type', fuelType || '', {
        shouldValidate: !!fuelType
      })
    })
  }

  useEffect(() => {
    if (isDirty) {
      fetchAndUpdateCarrier()
    }
  }, [usdotDebounced])

  useEffect(() => {
    if (isDirty) {
      fetchAndUpdateClassAndFuel()
    }
  }, [vinDebounced])

  useEffect(() => {
    if (isDirty) {
      fetchAndUpdateVin()
    }
  }, [lpnDebounced, lpnState])

  return (
    <Col items="stretch" gap={20}>
      <Controller
        name="power_unit_owner_id"
        control={control}
        render={({ field, formState }) => (
          <Input
            uppercase
            fullWidth
            {...field}
            label="ID"
            error={!!formState.errors.power_unit_owner_id}
            helperText={formState.errors.power_unit_owner_id?.message}
          />
        )}
      />

      <Row gap={16}>
        <Controller
          name="power_unit_carrier_usdot"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              required={isUsdotRequired}
              fullWidth
              digitsOnly
              name={field.name}
              type="number"
              label="US DOT #"
              value={field.value}
              onChange={field.onChange}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        {carrierLoading ? (
          <ReadOnlyFormValue required title="Carrier" loading />
        ) : (
          <Controller
            name="power_unit_carrier_name"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                required={isUsdotRequired}
                label="Carrier"
                name={field.name}
                inputValue={field.value}
                options={carriersList || []}
                loading={carriersLoading}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onInputChange={(e, newValue) => field.onChange(newValue)}
                getOptionLabel={(option: Carrier) => option?.name || ''}
                customOptionLabel={(option: Carrier) =>
                  `${option.name} (${option.us_dot_number})`
                }
                isOptionEqualToValue={(option: Carrier) =>
                  usdotDebounced
                    ? option.name === field.value &&
                      option.us_dot_number === usdotDebounced
                    : option.name === field.value
                }
                onChange={(value: Carrier) => {
                  field.onChange(value?.name || '')
                  setValue(
                    'power_unit_carrier_usdot',
                    value?.us_dot_number || ''
                  )
                }}
                inputProps={{
                  uppercase: true
                }}
              />
            )}
          />
        )}
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_license_plate_number')}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!errors.power_unit_license_plate_number}
          helperText={errors.power_unit_license_plate_number?.message}
        />

        <Controller
          name="power_unit_license_plate_state"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              required
              freeSolo={false}
              name={field.name}
              label="LPN State/Region"
              options={statesOptions}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={(value: ISelectOption) =>
                field.onChange(value?.id || '')
              }
              isOptionEqualToValue={(option: ISelectOption) =>
                option.id === field.value
              }
            />
          )}
        />
      </Row>

      {classAndFuelLoading ? (
        <Row gap={16}>
          <ReadOnlyFormValue title="Class" required loading />
          <ReadOnlyFormValue title="Fuel Type" required loading />
        </Row>
      ) : (
        <Row gap={16}>
          <Controller
            name="power_unit_weight_class"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Class"
                name={field.name}
                value={field.value}
                options={classListSelectOptions}
                error={!!formState.errors.power_unit_weight_class}
                helperText={formState.errors.power_unit_weight_class?.message}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="power_unit_fuel_type"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Fuel Type"
                name={field.name}
                value={field.value}
                options={fuelTypeSelectOptions}
                error={!!formState.errors.power_unit_fuel_type}
                helperText={formState.errors.power_unit_fuel_type?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Row>
      )}

      <Row gap={16}>
        <ReadOnlyFormValue
          title="VIN"
          value={vinDebounced}
          loading={vinLoading}
        />

        {showAccount && (
          <Controller
            name="account_name"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                freeSolo={false}
                label="Account"
                name={field.name}
                options={accounts}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={(value: ISelectOption) =>
                  field.onChange(value?.id || '')
                }
                isOptionEqualToValue={(option: ISelectOption) =>
                  option.id === field.value
                }
              />
            )}
          />
        )}
      </Row>
    </Col>
  )
}

export default DriverDetailsPowerUnitFormSection
